import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { RosterTemplateDownloadForm } from "../components/forms";
import { CallToAction, CustomerQuote } from "../components/site";
import {
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
  Button,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

import HeroGraphic from "../images/graphics/DroppahGraphic_Home.svg";

const FormContainer = styled(Box)`
  padding: 40px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colours.deeppurple};
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 30px;
  }
`;

const HeroImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
  width: 150%;
  max-width: 150%;
  opacity: 0.1;
`;

const ComparisonRow = styled(Row)`
  position: relative;

  > div:not(.vs) {
    padding: 30px 0;

    &:first-child {
      border-right: solid 1px rgba(255, 255, 255, 0.2);

      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        border-right: none;
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
        padding-bottom: 80px;
        margin-bottom: 50px;
      }
    }
  }

  .vs {
    --item-size: 50px;

    position: absolute;
    width: var(--item-size);
    height: var(--item-size);
    border-radius: var(--item-size);
    line-height: var(--item-size);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${(props) => props.theme.colours.black};
    background-color: ${(props) => props.theme.colours.white};
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

const RosterTemplate = (props) => {
  const Old = [
    "1 hour spent fixing a clunky timesheet",
    "3 systems to build, record + export time",
    "Manual time entry into payroll",
    "Time-consuming communication",
    "Paid and unpaid break confusion",
    "Scrambled cost reporting",
  ];

  const New = [
    "2 minutes to edit and complete",
    "1 system for the whole process",
    "Automatic time upload into payroll",
    "Send updated rosters via email",
    "Use default breaks to pay employees right",
    "Understand the actual cost of a roster",
  ];

  return (
    <Layout>
      <Seo
        title="Download Roster Template | Droppah"
        pathname={props.location.pathname}
      />
      <Container>
        <HeroImage src={HeroGraphic} alt="Droppah Rostering" />
        <Wrapper>
          <Row stackGap={120}>
            <Box size={55} stackGap={70} centerOnMobile>
              <Box stackGap={10} size={100}>
                <h1>Your free rostering template is here</h1>
                <h2 className="-fontNormal">Make a staff roster in Excel</h2>
              </Box>
              <Image
                filename="RosterTemplateHero.png"
                alt="Droppah | Rosters, Time Tracking & Attendance Software"
                maxWidth={4300}
                centerImage
                addShadow
              />
              <Box stackGap={20}>
                <p className="-textCenter" css={{ color: "#999" }}>
                  <Link to="/signup">Join</Link> the businesses that have
                  already swapped from Excel spreadsheets to Droppah.
                </p>
                <Image
                  filename="Droppah_CustomerLogos.png"
                  alt="Droppah Customers"
                  centerImage
                />
              </Box>
            </Box>
            <Box size={45}>
              <FormContainer stackGap={40}>
                <h2 className="-textCenter">Download roster template</h2>
                <RosterTemplateDownloadForm />
              </FormContainer>
            </Box>
          </Row>
          <h4 className="-fontNormal -textCenter">
            10 or fewer employees?{" "}
            <Link to="/signup">
              Use free AI-optimised rostering software instead
            </Link>
            .
          </h4>
        </Wrapper>
      </Container>
      <Container bg="deeppurple" className="-textCenter -whiteOut">
        <Wrapper>
          <h2>Why choose Droppah over rostering templates and Excel?</h2>
          <ComparisonRow>
            <Box size={50} stackGap={30}>
              <h3 className="h2 -fontBold">
                📺 <br />
                Old-school rostering
              </h3>
              {Old.map((item, i) => {
                return (
                  <h4 className="-fontNormal" key={i}>
                    {item}
                  </h4>
                );
              })}
            </Box>
            <Box size={50} stackGap={30}>
              <h3 className="h2 -fontBold">
                🖥 <br />
                Modern rostering
              </h3>
              {New.map((item, i) => {
                return (
                  <h4 className="-fontNormal" key={i}>
                    {item}
                  </h4>
                );
              })}
            </Box>
            <div className="vs">vs</div>
          </ComparisonRow>
        </Wrapper>
      </Container>
      <Container bg="mint">
        <Wrapper noPaddingBottom>
          <Box stackGap={80}>
            <Box className="-textCenter">
              <h2>Why choose Droppah over rostering templates and Excel?</h2>
              <h4>
                Roster your employees using Droppah to save costs, improve
                communication and keep your business running smoothly.
              </h4>
            </Box>
            <Box stackGap={70}>
              <FlexButtons justify="center">
                <Button className="primary -lg gtm-cta" to="/signup">
                  Start Rostering with Droppah
                </Button>
                <Button className="grey -lg" to="#">
                  Go back to the Excel template
                </Button>
              </FlexButtons>
              <CustomerQuote
                quote="Before Droppah, our rosters were manual and problematic… Droppah has given us the ability to see every shift in detail, plus what our team is doing in real-time."
                name="Brett Pearson"
                company="Eat My Lunch"
                pic="Brett_Circle.png"
                logo="EML_Logo.png"
                large
                center
              />
            </Box>
            <Image
              filename="DroppahHero_Cropped.png"
              alt="Droppah | Rosters, Time Tracking & Attendance Software"
              maxWidth={1100}
              centerImage
              addShadow
            />
          </Box>
        </Wrapper>
      </Container>
      <CallToAction />
    </Layout>
  );
};

export default RosterTemplate;
